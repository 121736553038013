<template>
  <h1 id="title">Ray's Site</h1>
  <div id="container">
    <p>The site is coming...</p>
  </div>

  <div class="footer">
    <a id="beian" href="https://beian.miit.gov.cn/" target="_blank"
      >蜀ICP备2021024072号-1</a
    >
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="less">
:root {
  --footer-height: 50px;
}

body {
  padding: 0;
  margin: 0;
}
#title {
  font-family: "NotoSerif-Black";
}

#container {
  min-height: 100%;
}

.footer {
  position: fixed;
  bottom: 0px;
  background-color: white;
  line-height: var(--footer-height);
  width: 100%;
  text-align: center;
  a {
    text-decoration: none;
    color: black;
  }
}
</style>
